import gql from 'graphql-tag';

export const LIST_VIEWER_ACCOUNTS = gql`
    query ListViewerAccounts($first: Int!, $cursor: String) {
        viewer {
            id
            accounts(first: $first, after: $cursor) {
                edges {
                    node {
                        avatar
                        id
                        name
                        type
                        identifiers(first: 10) {
                            edges {
                                node {
                                    id
                                    default
                                    subject
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const SHOW_ACCOUNT = gql`
    query ShowAccount($id: ID!) {
        account(id: $id) {
            id
        }
    }
`;
