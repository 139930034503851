export const TIMELINE = {
    INITIAL_STATE: {
        past: [],
        now: {},
        future: [],
    },

    RECORD: 'TIMELINE_RECORD',
    REDO: 'TIMELINE_REDO',
    RESET: 'TIMELINE_RESET',
    UNDO: 'TIMELINE_UNDO',
};

export const TimelineReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case TIMELINE.RECORD:
            if (!draft.now._equals({})) {
                draft.past = [...draft.past, draft.now];
            }
            draft.future = [];
            draft.now = payload.happening;
            break;

        case TIMELINE.REDO:
            draft.past = [...draft.past, draft.now];
            draft.now = draft.future.shift();
            break;

        case TIMELINE.RESET:
            draft.now = payload.now || TIMELINE.INITIAL_STATE.now;
            draft.past = TIMELINE.INITIAL_STATE.past;
            draft.future = TIMELINE.INITIAL_STATE.future;
            break;

        case TIMELINE.UNDO:
            draft.future = [draft.now, ...draft.future];
            draft.now = draft.past.pop();
            break;

        default:
            return draft;
    }
};
