import { i18next } from './IntlProvider';

const languages = [
    {
        code: 'en',
        text: 'English',
    },
    {
        code: 'es',
        text: 'Español',
    },
];

export { i18next as i18n, languages };
