import React from 'react';
import { Route } from 'react-router-dom';
import ls from 'local-storage';
import { PAGES, URL } from '../_config';

export const Passport = ({ component: Component, ...rest }) => {
    const { auth = {} } = ls('user') || {};
    const { token } = auth;
    const { location } = rest;
    let { pathname } = location;
    if (token && Component) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    if (pathname === PAGES.SIGN_IN) {
        pathname = PAGES.APP;
    }

    let referrer = `${URL.FACE}${pathname}`;
    window.location.href = `${URL.DOYO}${PAGES.SIGN_IN}/${referrer}`;
    return null;
};
