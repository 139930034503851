import gql from 'graphql-tag';

export const SHOW_VIEWER = gql`
    query ShowViewerUser {
        viewer {
            fullName
            id
            name
            accounts(first: 1) {
                edges {
                    node {
                        avatar
                        id
                        name
                        type
                        identifiers(first: 1) {
                            edges {
                                node {
                                    default
                                    id
                                    subject
                                    type
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
