// @flow
import React from 'react';
import { circlePreloader } from '../assets/svg/preloaders';

type PropsT = {
    className?: string,
};

export const CirclePreloader = (props: PropsT) => {
    const { className } = props;

    return (
        <div {...props} className={`preloader preloader-circle ${className ? className : ''}`}>
            <img alt="preloader" src={circlePreloader} />
        </div>
    );
};
