import React, { createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { TimelineReducer, TIMELINE } from './reducers';
import { PAGES } from '../_config';

export const TimelineContext = createContext({});

export const TimelineProvider = (props) => {
    const history = useHistory();
    const [state, dispatch] = useImmerReducer(TimelineReducer, TIMELINE.INITIAL_STATE);
    const { past, now, future } = state;

    const goToChange = (next) => {
        const { app } = now.data;
        const { project_name: projectName } = app;
        const diff = now.data._diff(next.data);
        let mainKey = Object.keys(diff)[0];
        if (!mainKey) {
            return;
        }
        const index = Object.keys(diff[mainKey])[0];
        const { id } = next.data[mainKey][index] || {};

        if (!id) {
            mainKey = 'app';
        }

        history.push(
            `${PAGES.APP}/${projectName}${mainKey ? `/${mainKey}` : ''}${id ? `/${id}` : ''}`
        );
    };

    const undo = () => {
        if (!past.length) {
            return;
        }
        goToChange(past[past.length - 1]);
        dispatch({ type: TIMELINE.UNDO });
    };

    const redo = () => {
        if (!future.length) {
            return;
        }
        goToChange(future[0]);
        dispatch({ type: TIMELINE.REDO });
    };

    const record = (happening) => {
        dispatch({ type: TIMELINE.RECORD, payload: { happening } });
    };

    const reset = (now) => {
        dispatch({ type: TIMELINE.RESET, payload: { now } });
    };

    return (
        <TimelineContext.Provider value={{ future, now, past, record, redo, reset, undo }}>
            {props.children}
        </TimelineContext.Provider>
    );
};

export const TimelineConsumer = TimelineContext.Consumer;
export default TimelineContext;
