import gql from 'graphql-tag';

export const LIST_ACCOUNT_IDENTIFIERS = gql`
    query ListAccountIdentifiers($accountId: ID!, $first: Int!, $cursor: String) {
        account(id: $accountId) {
            id
            identifiers(first: $first, after: $cursor) {
                edges {
                    node {
                        default
                        id
                        subject
                        type
                        verifiedAt
                        verifications(first: 1) {
                            edges {
                                node {
                                    id
                                    type
                                    createdAt
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const SHOW_IDENTIFIER = gql`
    query ShowIdentifier($id: ID!) {
        identifier(id: $id) {
            id
        }
    }
`;
