import React from 'react';

export const ProgressIndeterminate = ({ visible }) => {
    return (
        <div className={`progress${visible ? ` show` : ''}`}>
            <div className="indeterminate"></div>
        </div>
    );
};

export const ProgressDeterminate = ({ percentage }) => {
    return (
        <div className="progress">
            <div className="determinate" style={{ width: `${percentage}%` }}></div>
        </div>
    );
};
